<template>
  <div class="areas-edit animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle py-3">Edit Area</h4>
          <div class="main-contents">
            <addresses
              class="row col-12"
              v-if="area.country"
              isEdit
              :country.sync="area.country"
              :state.sync="area.state"
              :city.sync="area.city"
              :showArea="false"
              @change="
                (data) => {
                  area.countryId = data.country.id;
                  area.stateId = data.state.id;
                  area.cityId = data.city.id;
                }
              "
            ></addresses>
            <fd-input
              class="mt-2"
              type="text"
              :label="`Area Name`"
              v-model="area.name"
              :validators="[validator.required]"
            />
            <fd-input
              class="mt-2"
              type="text"
              :label="`Postal Code`"
              v-model="area.postalCode"
              :validators="[validator.required]"
            />

            <fd-input
              class="mt-2"
              type="text"
              :label="`Official Name`"
              v-model="area.officialName"
              :validators="[validator.required]"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import Addresses from "@/components/GlobalComponents/Address";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { AreaModel } from "@/models";

export default {
  name: "areas-edit",
  components: {
    Addresses
  },
  data() {
    return {
      validator: {
        required: required
      },
      area: {
        countryId: "",
        stateId: "",
        cityId: "",
        name: "",
        postalCode: "",
        officialName: ""
      },
      cloneArea: {
        countryId: "",
        stateId: "",
        cityId: "",
        name: "",
        postalCode: "",
        officialName: ""
      }
    };
  },
  async mounted() {
    this.$store.commit("setIsLoading", true, { root: true });
    await this.loadArea();
    this.$store.commit("setIsLoading", false, { root: true });
  },
  watch: {},
  methods: {
    async loadArea() {
      const id = this.$route.params.id;
      try {
        const request = await this.$store.dispatch("manageAreas/getArea", id);

        this.area = AreaModel.getAreaForResponse(this._.cloneDeep(request));
        this.cloneArea = AreaModel.getAreaForResponse(
          this._.cloneDeep(request)
        );

        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        await this.$store.dispatch("manageAreas/updateAreas", {
          id: id,
          data: AreaModel.postAreaPayload(this.area)
        });

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Area updated successfully."
        });
        this.$router.push({ path: "/manage-areas" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.areas-edit {
  form {
    @extend %formDesign;
  }
}
</style>